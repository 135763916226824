@import '/src/styles/variables';
@import '/src/styles/typo';

.table {margin: 0 (-$padding);
  &_row {position: relative; display: flex; border-bottom: 1px solid rgba($black, 0.1);
    &:nth-child(2n + 1):not(.table_row__header) {
      background: $bg-active;
    }
    &__header {background: $primary;
      .table_cell {@extend .text__large; color: $text-opposite; border: none; height: 40px; display: flex;
        align-items: center; font-weight: bold;}
    }
  }
  &_cell {flex-grow: 0; flex-shrink: 0; padding: 8px $padding; box-sizing: border-box;
    display: flex; align-items: center;
    @media (max-width: $mobile) {padding: 16px $padding;}
    &:first-of-type {padding-left: $padding;}
    &:last-of-type {padding-right: $padding;}
    &__header {background: $primary;@extend .text__large; color: $text-opposite; border: none; height: 40px; display: flex; align-items: center; font-weight: bold;}
  }
  &_spinner {min-height: 200px; display: flex; align-items: center; justify-content: center;}
}
