@import '/src/styles/variables';

.videobox {width: 100%; height: 0; padding-top: 47.33%; position: relative;
  &_wrapper {position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;
    outline: none; border-radius: $radius; background-size: cover;
    &:hover {cursor: pointer;}
    @media (max-width: $mobile) {border-radius: $radius-small;} 
  }
  &_wrapper:hover:not(&_activated) > &_btn {background-color: #f00; opacity: 1;}
  &_iframe {width: 100%; height: 100%;}
  &_btn, &_btn:before {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
  &_btn {border: none;}
  &_btn {width: 68px; height: 48px; background-color: #212121; z-index: 1; opacity: 0.8; border-radius: 20%; transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    @media (max-width: $mobile) {width: 45px; height: 33px;}
    &:before {content: ''; border-style: solid; border-width: 11px 0 11px 19px; margin-left: 3px; border-color: transparent transparent transparent #fff; transform: translate3d(-50%, -50%, 0);
      @media (max-width: $mobile) {border-width: 8px 0 8px 15px;}
    }
  }
  &_activated {cursor: unset;}
  &_activated::before, &_activated > &_btn {opacity: 0; pointer-events: none;}
}
