@import '/src/styles/variables';
@import '/src/styles/typo';

.mineralbuyer {height: 100%; display: flex; flex-direction: column;
  &_items {}
  &_item {@extend .text__large; line-height: $icon-size; padding-bottom: 0;
    &:not(:last-child) {
      @media (max-width: $mobile) {padding-bottom: 8px;}
    }
  }
  &_actions {padding: $padding 0; margin-top: auto;}
  &_button {
    @media (max-width: $mobile) {display: block; text-align: center;}
  }
}
