@import '/src/styles/variables';

.homepage {display: grid; gap: $padding; margin: $padding 0 0;
  grid-template-columns: 66% 1fr; grid-auto-rows: auto;
  grid-template-areas:
    "title title"
    "tabs tabs"
    "video video"
    "listings mineral"
    "review review";
  @media (max-width: $tablet) {grid-template-columns:100%; width: 100%; column-gap: 0;
    grid-template-areas:
    "title"
    "tabs"
    "video"
    "review"
    "listings"
    "mineral";
  }
  &_title {grid-area: title;}
  &_video {grid-area: video;}
  &_tabs {grid-area: tabs;}
  &_listings {grid-area: listings;}
  &_mineral {grid-area: mineral;}
  &_review {grid-area: review;}
}
