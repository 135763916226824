@import '/src/styles/variables';
@import '/src/styles/typo';

.reviewbox {border-radius: $radius;
  @media (max-width: $tablet) {border-radius: 8px;}
  &_review {margin: $padding auto; display: block;}
  &_slide {margin-bottom: 20px;
    &_inner {display: flex; justify-content: space-between; align-items: center;}
  }
  &_description {margin: 8px 0; padding-bottom: 25px;
    @media (max-width: $mobile) {margin: 4px 0;}
    p {margin-bottom: 25px;}
  }
  &_list {display: flex; flex-direction: column; justify-content: space-evenly; height: 100%; box-sizing: border-box;
    &__item {display: flex; align-items: center;}
  }
  .table {
    @media (max-width: $mobile) {margin: 0 -16px;}
    &_row {border: none;
      &__desktop {
        @media (max-width: $tablet) {display: none;}
      }
      @media (max-width: $tablet) {flex-direction: column;}
    }
    &_cell {box-sizing: border-box; border-left: 1px solid rgba($black, 0.1);
      &:first-of-type {border-left: none;}
      &__properties {width: 25%; flex-basis: 25%;}
      &__testimonials {width: 50%; flex-basis: 50%; align-self: flex-start;
        @media (max-width: $mobile) {padding-bottom: 0;}
      }
      &__trusted {width: 25%; flex-basis: 25%;}
      &__accented {background: $gray;}
      @media (max-width: $tablet) {width: 100%; flex-basis: 100%;}
    }
    &_header {
      &__tablet {display: none;
        @media (max-width: $tablet) {display: flex;}
      }
      &__gray {background: $gray;}
    }
  }
}
