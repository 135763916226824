@import '/src/styles/variables';
@import '/src/styles/typo';

.slider {
  &_wrapper {margin: 0 (-$padding); display: flex; align-items: stretch; padding: 20px 0;
    @media (max-width: $mobile) {flex-wrap: wrap; padding: 0; border-radius: $radius-small $radius-small 0 0; overflow: hidden; margin: 0 -16px;}
  }
  &_swiper {width: 100%;
    @media (max-width: $mobile) {flex: 1 0 100%;}
  }
  &_main {flex: 0 0 66.66%; position: relative; height: 100%; box-sizing: border-box; color: $text-opposite; display: flex; flex-direction: column; z-index: 1; max-height: 310px;
    @media (max-width: $mobile) {max-height: none;}
  }
  &_image {height: 100%; width: 100%; z-index: -1;
    @media (max-width: $mobile) {object-fit: contain;}
  }
  &_content {position: absolute; inset: 0; padding: 44px 58px 48px;
    @media (max-width: $mobile) {display: none;}
  }
  &_title {@extend .title__large; margin: 0; margin-bottom: $padding;}
  &_description {@extend .text__largest; max-width: 290px;}
  &_actions {margin-top: auto;}
  &_rightbar {flex: 0 0 33.33%; height: 100%;
    @media (max-width: $mobile) {flex: 0 0 100%; min-width: 100%; padding: 10px; box-sizing: border-box;}
  }
}

.tab {@extend .text__largest; width: 100%; height: 62px; display: block; position: relative;
  display: flex; align-items: center; padding: 0 24px; box-sizing: border-box;
  background: none; border: none; outline: none; z-index: 1; border-bottom: 1px solid rgba($black, .1);
  @media (max-width: $mobile) {font-size: $text-default; height: 40px; border-radius: $radius-small; border: none;}
  &::before {content: ''; opacity: 0; position: absolute; top: 0; left: -30px;
    border-color: transparent; border-style: solid;
    border-top-width: 30px; border-bottom-width: 30px;
    border-right-width: 15px; border-left-width: 15px;
    border-right-color: $secondary;
    @media (max-width: $mobile) {content: none;}
  }
  &__active {color: $text-opposite; background: $secondary;
    &::before {opacity: 1;}
  }
  &:first-of-type {border-top: 1px solid rgba($black, .1);
    @media (max-width: $mobile) {border: none;}
  }
}
