@import '/src/styles/variables';

.listings {display: flex; flex-direction: column; height: 100%;
  @media (max-width: $mobile) {overflow: hidden;}
  &_header {
    @media (max-width: $mobile) {display: none;}
  }
  &_item_actions {margin-left: auto;
    @media (max-width: $mobile) {height: 100%; width: 100%;}
  }
  &_actions {padding: $padding 0; margin-top: auto;}
  &_action {padding-left: 35px; padding-right: 16px;
    @media (max-width: $mobile) {display: block; text-align: center;}
  }
  &_table {
    @media (max-width: $mobile) {border-top: 1px solid rgba($black, 0.1); margin: 0 -16px;}
  }
  .table {
    &_row {
      @media (max-width: $mobile) {align-items: stretch;}
    }
    &_cell {
      &_content {
        &__desktop {
          @media (max-width: $mobile) {display: none;}
        }
        &__mobile {display: none;
          @media (max-width: $mobile) {display: block;}
        }
      }
      &_text {display: block; line-height: 1.43;
        &:not(:last-child) {margin-bottom: .42rem;}
      }
      &__type {width: 30%; flex-basis: 30%;
        @media (max-width: $mobile) {width: 25%; flex-basis: 25%; min-width: 50px;}
      }
      &__state {width: 25%; flex-basis: 25%;
        @media (max-width: $mobile) {flex: 1; width: auto; flex-basis: auto;}
      }
      &__county {width: 25%; flex-basis: 25%;
        @media (max-width: $mobile) {display: none;}
      }
      &__actions {width: 20%; flex-basis: 20%; display: flex; justify-content: flex-end; padding-right: 17px;
        @media (max-width: $mobile) {width: 25%; flex-basis: 25%; height: inherit; padding-right: 0; padding-left: 0;}
      }
    }
  }
  &_button {
    @media (max-width: $mobile) {background: none; border: none; height: inherit; display: flex; align-items: center; justify-content: flex-end;}
    &_text {
      @media (max-width: $mobile) {display: none;}
    }
  }
}

:export {
  mobile: $mobile
}
